import { ReactElement } from 'react';

import { Box, Button, Flex, Show, Text } from '@chakra-ui/react';
import DeadFace from 'images/doodles/dead-face.png';
import UnderlineImage from 'images/highlights/line-3-cross-2.svg';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Image from 'next/image';
import Link from 'next/link';

import { GradientBackground } from '@/components/atoms';
import { StaticBackground } from '@/components/atoms/StaticBackground';
import { TextDecorationSvg } from '@/components/functional';
import { DefaultLayout, Footer } from '@/components/layouts';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { PropsBuilder } from '@/graphql/api';
import { ButtonVariant, useGetGlobalQuery } from '@/graphql/generated';
import { DEFAULT_LOCALE, HEADER_HEIGHT, LOCALES, PAGE_REVALIDATE_TIME } from '@/utils/constants';
import { GeneralPath } from '@/utils/models';

import { NextPageWithLayout } from './_app';

const FourOhFour: NextPageWithLayout = () => {
  const { t } = useTranslation([LOCALES.COMMON]);

  return (
    <SectionWrapper minH={`calc(100vh - ${HEADER_HEIGHT})`} display="flex" alignItems="center">
      <SectionContentWrapper paddingX={{ base: 5, sm: 5, lg: 20, '2xl': 40 }} w="100%">
        <StaticBackground left="-30%" bottom="-40%" w="800px" h="800px" />
        <GradientBackground position="absolute" isInView={true} right="-20%" top={0} h="650px" w="650px" zIndex={-1} />
        <Flex>
          <Flex flexDir="column" alignSelf="center" mx={{ base: 'auto', sm: 0 }} maxW="full" flex={1}>
            <Text
              as="h1"
              color="purple.500"
              textStyle={{
                base: 'component-heading-large/xl',
                xs: 'component-heading-large/3xl',
                lg: 'component-heading-large/4xl',
              }}
              mb={{
                base: 14,
                xs: 18,
                lg: 32,
              }}
            >
              {t('notFound.title')}
              <Text
                as="span"
                display="inline-block"
                transform="rotate(26.79deg) translate(-0.2em, 0.2em)"
                transformOrigin="0% 100%"
                textStyle={{
                  base: 'component-heading-large/lg',
                  xs: 'component-heading-large/2xl',
                  lg: 'component-heading-large/3xl',
                }}
              >
                404
              </Text>
              <br />
              <Box as="span" whiteSpace="nowrap">
                {t('notFound.page')}{' '}
                <TextDecorationSvg line={UnderlineImage} speed="0.5s">
                  {t('notFound.underlined')}
                </TextDecorationSvg>{' '}
                😢
              </Box>
            </Text>
            <Text
              color="purple.400"
              textStyle={{
                base: 'text-md/lineHeight-6/font-normal',
                xs: 'text-lg/lineHeight-7/font-normal',
                lg: 'text-2xl/lineHeight-8/font-normal',
              }}
              maxW={{
                base: '248px',
                xs: '350px',
                lg: '462px',
              }}
              mb={{
                base: 8,
                xs: 10,
              }}
            >
              {t('notFound.desc')}
            </Text>
            <Button
              as={Link}
              href={GeneralPath.HOME}
              variant={ButtonVariant.Light}
              w={{
                base: '100%',
                '2xs': '302px',
              }}
              maxW="full"
              size="lg"
            >
              {t('notFound.btn')}
            </Button>
          </Flex>
          <Show above="sm">
            <Flex
              alignItems="center"
              mr={{
                md: 14,
                xl: 28,
              }}
              mt={{
                sm: -20,
                md: 0,
              }}
            >
              <Box
                as={Image}
                src={DeadFace}
                alt=""
                mx="auto"
                w="100%"
                h="fit-content"
                alignSelf="top"
                minW="260px"
                maxW="462px"
              />
            </Flex>
          </Show>
        </Flex>
      </SectionContentWrapper>
    </SectionWrapper>
  );
};

FourOhFour.getLayout = (page: ReactElement) => {
  const { metadata } = page.props;

  return (
    <DefaultLayout metadata={metadata}>
      {page}
      <Footer />
    </DefaultLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const propsBuilder = new PropsBuilder();

  propsBuilder.withQuery(useGetGlobalQuery, {
    variables: {
      locale,
    },
  });

  const lang = await serverSideTranslations(locale || DEFAULT_LOCALE, [LOCALES.COMMON]);

  return {
    props: {
      ...(await propsBuilder.build(lang)),
    },
    revalidate: PAGE_REVALIDATE_TIME,
  };
};

export default FourOhFour;
